<template>
    <v-container class="cookie-consent" v-if="showCookieConsent">
      <v-row justify="center" align="center" no-gutters>
        <v-col cols="7">
          <v-card class="pa-4 cookie-card">
            <v-card-text class="d-flex justify-space-between align-center">
              <span>Este site usa cookies para melhorar sua experiência. Para saber mais, acesse nossos <a href="/pdf/TERMO_E_USO_plataforma_Belat.pdf" target="_blank" @click.stop><b><u>Termos e Condições.</u></b></a></span>
              <v-btn
                color="primary"
                class="transition-fast-in-fast-out v-btn--rounded"
                @click="acceptCookies"
                
              >
                Entendi
              </v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    data() {
      return {
        showCookieConsent: false,
      };
    },
    created() {
      this.checkCookieConsent();
    },
    methods: {
      checkCookieConsent() {
        const consent = this.getCookie('cookie_consent');
        if (!consent) {
          this.showCookieConsent = true;
        }
      },
      acceptCookies() {
        const expirationDays = 365;
        this.setCookie('cookie_consent', 'accepted', expirationDays);
        this.showCookieConsent = false;
      },
      setCookie(name, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        const expires = `expires=${date.toUTCString()}`;
        document.cookie = `${name}=${value};${expires};path=/`;
      },
      getCookie(name) {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) === ' ') c = c.substring(1, c.length);
          if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
      },
    },
  };
  </script>
  
  <style scoped>
  .cookie-consent {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 0 16px;
  }
  
    .cookie-card {
        margin: 0;
        width: 100%;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    }
  
  .v-card-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .v-btn {
    min-width: auto;
  }
  </style>
  