<template>
    <v-app>
        <v-main style="background: #00265b; overflow-x:hidden">
            <div class="white">
                <div class="d-flex flex-column items-center">
                    <div class="px-6 py-5 d-flex align-center"
                        style="justify-content: center">
                        <div class="d-flex">
                            <div class="text-h5 font-weight-medium text-center primary--text "
                                style="display: flex; align-items: center; font-family: 'Raleway'">
                                Dados pessoais
                            </div>
                        </div>
                    </div>
                </div>
                <v-row class="d-flex justify-center">
                    <div class="">
                      <svg width="230" height="4" viewBox="0 0 230 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="230" height="4" rx="2" fill="#D9D9D9" />
                        <path d="M0 2.08696C0 0.934362 0.934362 0 2.08696 0H23V4H1.91304C0.8565 4 0 3.1435 0 2.08696Z"
                          fill="#14AA95" />
                        <path d="M46 0H69V4H46V0Z" fill="#14AA95" />
                        <path d="M23 0H46V4H23V0Z" fill="#14AA95" />
                        <path d="M69 0H92V4H69V0Z" fill="#14AA95" />
                        <path d="M92 0H115V4H92V0Z" fill="#14AA95" />
                        <path d="M115 0H138V4H115V0Z" fill="#14AA95" />
                        <path d="M138 0H161V4H138V0Z" fill="#14AA95" />
                        <path d="M161 0H184V4H161V0Z" fill="#14AA95" />
                        <path d="M184 0H207V4H184V0Z" fill="#14AA95" />
                      </svg>
              
                      <!-- <QuestionTimeLine :items="currentTimeline" :activeIndex="currentPosition"
                          :completedIndexes="completedIndexes" /> -->
                    </div>
                  </v-row>
            </div>
                <!-- <template v-if="currentQuestionIndex == 5">
                    <div class="mx-3">
                    <span class="white--text text-h5 font-weight-bold text-center">Clique para fazer o upload do seu comprovante</span>
                    </div>
                </template> -->
                <div class="mt-10">
                    <v-col style="justify-content: center" >
                        <div v-if="currentQuestionIndex == 0">
                            <v-row class="px-4">
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark placeholder="Nome completo" :rules="rules" label="Nome completo" outlined dense
                                        v-model="user.nome"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark :rules="rules" :error="cpfError" required
                                        @blur="titularUser.cnpj_cpf = formatarCPF(titularUser.cnpj_cpf)"
                                        @input="titularUser.cnpj_cpf = formatarCPF(titularUser.cnpj_cpf)" maxlength="14"
                                        label="CPF" outlined dense v-model="formattedValorCPF"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark :error="dateError" :rules="rulesNascimento" type="date" required
                                        label="Data de nascimento" v-model="pessoaFisica.data_de_nascimento" outlined
                                        dense></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-select class="fieldColor" dark :error="ufExError" :rules="rules" label="Uf" color="secondary"
                                        v-model="pessoaFisica.uf_orgao_expeditor"
                                        :items="getSelectItems({ text: 'UF' })" item-text="codigo" item-value="codigo"
                                        outlined dense></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-select class="fieldColor" dark :error="expedidorError" :rules="rules" label="Órgão" color="secondary"
                                        v-model="pessoaFisica.orgao_expeditor"
                                        :items="getSelectItems({ text: 'Orgão Expedidor' })" item-text="both"
                                        item-value="codigo" outlined dense></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark :error="identidadeError" :rules="rules"
                                        label="Documento de identificação (RG, CNH ou RNE)" required outlined dense
                                        v-model="pessoaFisica.identidade"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark :error="emissaoError" :rules="rules" type="date" required
                                        label="Data de emissão documento" v-model="pessoaFisica.data_emissao_identidade"
                                        outlined dense></v-text-field>
                                </v-col>
                            </v-row>
                        </div>

                        <div v-if="currentQuestionIndex == 1">
                            <v-row class="px-4">
                                <v-col cols="12">
                                    <v-select class="fieldColor" dark :error="civilError" :rules="rules" label="Estado Civil" color="secondary"
                                        v-model="pessoaFisica.estado_civil_id"
                                        :items="getSelectItems({ text: 'Estado Civil' })" item-text="nome"
                                        item-value="id" outlined dense></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-select class="fieldColor" dark :error="naturalidadeError" :rules="rules" label="Naturalidade"
                                        color="secondary" v-model="pessoaFisica.naturalidade"
                                        :items="getSelectItems({ text: 'Naturalidade' })" item-text="nome"
                                        item-value="id" outlined dense></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-select class="fieldColor" dark :error="nacionalidadeError" :rules="rules" label="Nacionalidade"
                                        color="secondary" v-model="pessoaFisica.nacionalidade"
                                        :items="getSelectItems({ text: 'Nacionalidade' })" item-text="nome"
                                        item-value="id" outlined dense></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-select class="fieldColor" dark :error="sexoError" :rules="rules" label="Sexo" color="secondary"
                                        v-model="pessoaFisica.sexo_id" :items="getSelectItems({ text: 'Sexo' })"
                                        item-text="nome" item-value="id" outlined dense></v-select>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark :error="maeError" :rules="rules" label="Nome da mãe" required outlined
                                        dense v-model="pessoaFisica.nome_da_mae"></v-text-field>
                                </v-col>
                            </v-row>
                        </div>

                        <div v-if="currentQuestionIndex == 2">
                             <v-row class="px-4">
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark :error="cargoError" :rules="rules" label="Cargo" outlined dense
                                        v-model="pessoaFisica.cargo"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark :error="patrimonioError" :rules="rules"
                                        label="Valor do Patrimônio Financeiro" @change="formatarSalario" outlined dense
                                        v-model="formattedValorPatrimonio"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark :error="salarioError" :rules="rulesSalario" :error-messages="salarioErrorMessage" label="Renda Mensal"
                                         @change="onChangeSalario" outlined dense
                                        v-model="formattedSalario"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark label="Email" outlined dense v-model="user.email"
                                        disabled></v-text-field>
                                </v-col>
                            </v-row>
                        </div>

                        <div v-if="currentQuestionIndex == 3">
                            <!-- <v-row>
                                <v-col cols="4">
                                    <v-combobox :rules="rules" :error="numeroBancoError" label="Número do banco"
                                        color="secondary" v-model="titularUser.numero_do_banco"
                                        :items="getSelectItems({ text: 'Número do banco' })" item-text="displayText"
                                        item-value="codigo" outlined dense></v-combobox>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field :rules="rules" :error="agenciaError" v-mask="'####'" label="Agência"
                                        outlined dense v-model="titularUser.numero_da_agencia_bancaria"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field :rules="rules" maxlength="13" :error="numeroContaError"
                                        label="Número da conta" outlined dense
                                        v-model="titularUser.numero_da_conta_corrente_bancaria"
                                        @blur="formatNumeroConta"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-select :rules="rules" :error="tipoContaError" label="Tipo de Conta"
                                        color="secondary" v-model="titularUser.tipo_de_conta_bancaria_id"
                                        :items="getReversedSelectItems({ text: 'Tipo de conta' })" item-text="nome"
                                        item-value="id" outlined dense></v-select>
                                </v-col>
                            </v-row> -->

                            <v-row class="px-4">
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark 
                                        @input="titularEndereco.cep = formatarCEP(titularEndereco.cep)"
                                        @blur="fetchCEPData(titularEndereco.cep)" label="CEP" outlined dense
                                        v-model="formattedValorCEP"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark :error="ruaError" :rules="rules" label="Rua" outlined dense
                                        v-model="titularEndereco.logradouro"></v-text-field>
                                </v-col>
                                <v-col cols="4">
                                    <v-text-field class="fieldColor" dark :error="numeroError" :rules="rules" label="Número" outlined dense
                                        v-model="titularEndereco.numero"></v-text-field>
                                </v-col>
                                <v-col cols="8">
                                    <v-text-field class="fieldColor" dark label="Complemento" outlined dense
                                        v-model="titularEndereco.complemento"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark :error="bairroError" :rules="rules" label="Bairro" outlined dense
                                        v-model="titularEndereco.bairro"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field class="fieldColor" dark :error="cidadeError" :rules="rules" label="Cidade" outlined dense
                                        v-model="titularEndereco.cidade"></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field class="fieldColor" dark :error="ufError" :rules="rules" label="Estado" outlined dense
                                        v-model="titularEndereco.uf"></v-text-field>
                                </v-col>
                            </v-row> 

                             <!-- <v-row>
                                <v-col cols="6">
                                    <v-text-field @input="user.phone_number = formatarPhone(user.phone_number)"
                                        label="Telefone" outlined dense v-model="user.phone_number"
                                        disabled></v-text-field>
                                </v-col>
                            </v-row> -->

                        </div>


                        <div v-if="currentQuestionIndex == 4">
                            <v-row class="px-4">
                                <v-col cols="12">
                                    <v-combobox class="fieldColor" dark :rules="rules" :error="numeroBancoError" label="Número do banco"
                                        color="secondary" v-model="titularUser.numero_do_banco"
                                        :items="getSelectItems({ text: 'Número do banco' })" item-text="displayText"
                                        item-value="codigo" outlined dense></v-combobox>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark :rules="rules" :error="agenciaError" v-mask="'####'" label="Agência"
                                        outlined dense v-model="titularUser.numero_da_agencia_bancaria"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field class="fieldColor" dark :rules="rules" maxlength="13" :error="numeroContaError"
                                        label="Número da conta" outlined dense
                                        v-model="titularUser.numero_da_conta_corrente_bancaria"
                                        @blur="formatNumeroConta"></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-select class="fieldColor" dark :rules="rules" :error="tipoContaError" label="Tipo de Conta"
                                        color="secondary" v-model="titularUser.tipo_de_conta_bancaria_id"
                                        :items="getReversedSelectItems({ text: 'Tipo de conta' })" item-text="nome"
                                        item-value="id" outlined dense></v-select>
                                </v-col>
                            </v-row>
                            
                        </div>

                        <div v-if="currentQuestionIndex == 5">
                            <div class="mx-3">
                                <span class="white--text font-weight-semibold text-center" style="font-size: 21px;">Clique para fazer o upload do seu documento de identificação</span>
                            </div>
                            <v-row class="d-flex justify-center align-center">
                                <v-col cols="12" class="px-10">                                   
                                        <h1
                                        class="white--text text-body-1 font-weight-light text-start pb-lg-5 px-lg-5 pt-10">
                                        {{ descRgFrente }}
                                        </h1>
                                    <v-card class="justify-center mx-6" @click="openFileInput('RgFrente')" style="min-width: 100px;">
                                        <div class="d-flex justify-center  py-5">
                                            <v-img v-if="titularUser.anexo_rg" class="ma-5" max-width="50"  contain
                                                :src="check"></v-img>
                                            <v-img v-else max-width="80" class="my-5 mb-2" :src="image"></v-img>
                                        </div>
                                    </v-card>
                                    <input type="file" ref="fileInputRgFrente" style="display: none"
                                        @change="handleFileUpload($event, 'anexo_rg')"
                                        accept="application/pdf, image/*">
                                </v-col>
                                <v-col cols="12" class="px-10">
                                    <h1
                                    class="white--text text-body-1 font-weight-light text-start pb-lg-5 px-lg-5">
                                    {{ descRgTras }}
                                </h1>
                                    <v-card class="d-grid justify-center mx-6" @click="openFileInput('RgTras')" style="min-width: 100px;">
                                        <div class="d-flex justify-center  py-5">
                                            <v-img v-if="titularUser.anexo_rg_tras" class="ma-5" max-width="50" contain
                                                :src="check"></v-img>
                                            <v-img v-else max-width="80" class="my-5 mb-2" :src="imageVerso"></v-img>
                                        </div>
                                    </v-card>
                                    <input type="file" ref="fileInputRgTras" style="display: none"
                                        @change="handleFileUpload($event, 'anexo_rg_tras')"
                                        accept="application/pdf, image/*">
                                </v-col>
                            </v-row>

                            
                            <!-- <div class="text-center" style="display: flex; justify-content: center; align-items: center;">
                                <span class="white--text text-h5 font-weight-bold text-center">Clique para fazer o upload do seu comprovante</span>
                            </div>
                            <v-row class="d-flex justify-center align-center">
                                <v-col cols="12">
                                    <v-card  style="background: transparent !important;" class="mt-10 d-grid justify-center"
                                        @click="openFileInput(titularUser.anexo_comprovante_residencia)">
                                        <div class="d-flex justify-center">
                                            <v-img v-if="titularUser.anexo_comprovante_residencia" class="mt-2"
                                                max-width="40" contain :src="check"></v-img>
                                            <v-img v-else max-width="80" class="mt-10" contain :src="imageVerso"></v-img>
                                        </div>
                                        <div class="px-lg-6">
                                            <h1
                                                class="white--text text-body-1 font-weight-bold text-center pb-lg-5 px-lg-5">
                                                {{ descComprovante }}
                                            </h1>
                                        </div>
                                    </v-card>
                                    <input type="file" :ref="'fileInput' + titularUser.anexo_comprovante_residencia"
                                        style="display: none"
                                        @change="handleFileUpload($event, 'anexo_comprovante_residencia')"
                                        accept="application/pdf, image/*">
                                </v-col>
                            </v-row> -->
                        </div>

                        <div v-if="currentQuestionIndex == 6">
                            <div class="text-center" style="display: flex; justify-content: center; align-items: center;">
                                <span class="white--text font-weight-semibold text-center mt-16" style="font-size: 21px;">Clique para fazer o upload do seu comprovante de endereço:</span>
                            </div>
                            <v-row class="d-flex justify-center align-center">
                                <v-col cols="12" class="px-10">
                                    <v-card  style="background: transparent !important;" class="mt-10 mx-6 d-grid justify-center"
                                        @click="openFileInput(titularUser.anexo_comprovante_residencia)">
                                        <div class="d-flex justify-center py-5">
                                            <v-img v-if="titularUser.anexo_comprovante_residencia" class="mt-2"
                                                max-width="50" contain :src="check"></v-img>
                                            <v-img v-else max-width="70" contain :src="enderecoIcon"></v-img>
                                        </div>
                                    </v-card>
                                    <input type="file" :ref="'fileInput' + titularUser.anexo_comprovante_residencia"
                                        style="display: none"
                                        @change="handleFileUpload($event, 'anexo_comprovante_residencia')"
                                        accept="application/pdf, image/*">
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>
                </div>




                    <div class="footer d-flex justify-center">
                        <v-btn color="primary" class="mr-4 px-12" dark style="border: 1px solid #14AA95 !important"
                            v-if="currentQuestionIndex == 0" rounded @click="backPage">Voltar</v-btn>
                        <v-btn class="mr-4 px-12 white--text" color="primary" style="border: 1px solid #14AA95 !important"
                            v-if="currentQuestionIndex > 0" rounded @click="--currentQuestionIndex">Voltar</v-btn>
                        <v-btn class="px-10" dark rounded color="secondary" @click="handleButtonClick">
                            {{ currentQuestionIndex < 6 ? 'Avançar' : 'Finalizar' }} </v-btn>
                    </div>

        </v-main>
    </v-app>
</template>


<script>
import rgFrente from "../../../assets/rgFrente.png"
import rgVerso from "../../../assets/rgVerso.png"
import Check from "../../../assets/check.png"
import Endereco from "../../../assets/endereco_icon.png"
import axios from "axios"
export default {
    props: {
        currentTimeline: Array,
        currentPosition: Number,
    },
    data() {
        return {
            uploadedImages: [],
            rules: [
                (v) => !!v || 'Campo obrigatório',
            ],
            rulesSalario: [
                v => !!v || 'Renda Mensal é obrigatória',
                v => !this.validarSalario(v) || `A Renda Mensal deve ser maior que R$ ${this.salarioMinimo}`
            ],
            cpfError: false,
            dateError: false,
            dateErrorMessage: '',
            rulesNascimento: [
                v => !!v || 'Data de nascimento é obrigatória',
                v => this.validarIdade(v) || 'Investidor deve ter pelo menos 18 anos'
            ],
            identidadeError: false,
            emissaoError: false,
            expedidorError: false,
            ufExError: false,
            sexoError: false,
            civilError: false,
            nacionalidadeError: false,
            naturalidadeError: false,
            maeError: false,
            cargoError: false,
            patrimonioError: false,
            cepError: false,
            ruaError: false,
            numeroError: false,
            bairroError: false,
            cidadeError: false,
            ufError: false,
            salarioError: false,
            salarioErrorMessage: '',
            salarioMinimo: 1320, // Defina o valor mínimo do salário aqui
            numeroBancoError: false,
            agenciaError: false,
            numeroContaError: false,
            tipoContaError: false,
            oi: null,
            descRgFrente: "Frente ",
            descRgTras: "Verso (Opcional)",
            descComprovante: "Upload do comprovante",
            check: Check,
            enderecoIcon: Endereco,
            image: rgFrente,
            imageVerso: rgVerso,
            pessoaFisica: {},
            activeIndex: 0,
            completedIndexes: [0, 1],
            userId: localStorage.getItem("userId"),
            titularUser: {},
            salario: null,
            token: localStorage.getItem("token"),
            urlEnv: process.env.VUE_APP_SERVICE_URL,
            datePicker: {},
            currentQuestionIndex: 0,
            answers: {},
            currentScreenIndex: 0,
            currentStage: 0,
            answering: false,
            titularEndereco: {},
            showOptions: false,
            genderItens: [],
            estadoCivil: [],
            nacionalidade: [],
            naturalidade: [],
            banco: [],
            tipoContaBancaria: [],
            camposObrigatorios: [
                'user.nome',
                'titularUser.cnpj_cpf',
                'pessoaFisica.data_de_nascimento'
            ],
            uf: [],
            user: {
                nome: null,
                email: null,
                phone_number: null
            },
            expeditor: [],
            stages: [
                {
                    position: 1,
                    title: '',
                    text: '',

                    questions: [
                        [
                            {
                                id: 'data',
                                type: 'select',
                                required: true,
                                text: '',
                                options: []
                            },
                        ],
                    ],
                },
            ]
        }
    },
    computed: {
        formattedNumeroConta() {
            return this.formatarNumeroConta(this.titularUser.numero_da_conta_corrente_bancaria);
        },
        dataFormatada: {
            get() {
                return this.formatarData(this.pessoaFisica?.data_de_nascimento);
            },
            set(value) {
                this.pessoaFisica.data_de_nascimento = value;
            },
        },
        emissaoFormatada: {
            get() {
                return this.formatarData(this.pessoaFisica?.data_emissao_identidade);
            },
            set(value) {
                return this.pessoaFisica.data_emissao_identidade = value
            }
        },
        formattedValorCEP: {
            get() {
                return this.formatarCEP(this.titularEndereco?.cep);
            },
            set(value) {
                this.titularEndereco.cep = value.replace(/\D/g, '')
            }
        },
        formattedValorCPF: {
            get() {
                return this.formatarCPF(this.titularUser.cnpj_cpf);
            },
            set(value) {
                this.titularUser.cnpj_cpf = value.replace(/\D/g, '');
            }
        },
        formattedValorPatrimonio: {
            get() {
                return this.formatarSalario(this.titularUser.valor_do_patrimonio);
            },
            set(value) {
                // Remova a máscara e atualize o valor
                this.titularUser.valor_do_patrimonio = value.replace(/\D/g, '').replace(',', '.').replace(/^(\d+)(\d{2})$/, "$1.$2");
            }
        },
        formattedSalario: {
            get() {
                return this.formatarSalario(this.pessoaFisica.salario);
            },
            set(value) {
                // Remova a máscara e atualize o valor
                this.pessoaFisica.salario = value.replace(/\D/g, '').replace(',', '').replace('.', '').replace(/^(\d+)(\d{2})$/, "$1.$2");
                this.validarSalario();
            }
        }
    },
    mounted() {
        this.getEstadosCivis()
        this.getSexos();
        this.getOrgaoExpeditor();
        this.getUf();
        this.getPais();
        this.getBancos();
        this.getTiposConta();
        this.getUserData()
        this.getInvestidor()
    },
    watch: {
        'pessoaFisica.data_de_nascimento': function (newVal) {
            this.dateError = !this.validarIdade(newVal);
            },
        'user.phone_number': function (newVal, oldVal) {
            if (newVal !== oldVal) {
                if (newVal) {
                    // Remove todos os caracteres não numéricos
                    const cleanedNumber = newVal.replace(/\D/g, '');
                    // Aplica a máscara correta
                    this.user.phone_number = cleanedNumber.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, '($1) $2 $3-$4');
                } else {
                    this.user.phone_number = '';
                }
            }
        },
    },
    methods: {
        validarSalario() {
            const salario = parseFloat(this.pessoaFisica.salario);
            console.log(salario)
            if (salario <= this.salarioMinimo) {
                this.salarioError = true;
                this.salarioErrorMessage = `A Renda Mensal deve ser maior que ${this.salarioMinimo}`;
            } else {
                this.salarioError = false;
                this.salarioErrorMessage = '';
            }
        },
        onChangeSalario() {
        // Chame a validação sempre que o valor mudar
        this.validarSalario();
        },
        validarIdade(data) {
            if (!data) return false;

            const today = new Date();
            const birthDate = new Date(data);
            const age = today.getFullYear() - birthDate.getFullYear();
            const monthDifference = today.getMonth() - birthDate.getMonth();
            const dayDifference = today.getDate() - birthDate.getDate();

            if (
                age > 18 ||
                (age === 18 && monthDifference > 0) ||
                (age === 18 && monthDifference === 0 && dayDifference >= 0)
            ) {
                return true;
            }

            this.dateError = true;
            this.dateErrorMessage = 'Investidor deve ter pelo menos 18 anos';
            return false;
            },
        getReversedSelectItems() {
            const items = this.getSelectItems({ text: 'Tipo de conta' }); // Supondo que você já tenha um método getSelectItems
            return items.reverse(); // Reverte a ordem dos itens
        },
        formatNumeroConta() {
            let numeroConta = this.titularUser.numero_da_conta_corrente_bancaria;
            while (numeroConta.length < 12) {
                numeroConta = "0" + numeroConta;
            }
            numeroConta = numeroConta.substring(0, numeroConta.length - 1) + "-" + numeroConta.charAt(numeroConta.length - 1);

            this.titularUser.numero_da_conta_corrente_bancaria = numeroConta;
        },
        getSelectItems(question) {
            const mappings = {
                'Sexo': { array: this.genderItens, idKey: 'id', nomeKey: 'nome' },
                'Estado Civil': { array: this.estadoCivil, idKey: 'id', nomeKey: 'nome' },
                'Orgão Expedidor': { array: this.expeditor, key: 'codigo', bothKey: 'both' },
                'UF': { array: this.uf, key: 'codigo', nomeKey: 'nome' },
                'Tipo de conta': { array: this.tipoContaBancaria, idKey: 'id', nomeKey: 'nome' },
                'Naturalidade': { array: this.naturalidade, key: 'nome', nomeKey: 'nome' },
                'Nacionalidade': { array: this.nacionalidade, key: 'nome', nomeKey: 'nome' },
                'Número do banco': { array: this.banco, key: 'codigo', nomeKey: 'nome' },
            };

            const mapping = mappings[question.text];
            if (mapping) {
                return mapping.array.map(item => ({
                    id: item[mapping.idKey],
                    codigo: item[mapping.key],
                    nome: item[mapping.nomeKey],
                    both: `${item.codigo}:${item.nome}`,
                    displayText: `${item.codigo} - ${item.nome}`
                }));
            } else {
                return [];
            }
        },
        formatarCEP(cep) {
            // Remove caracteres não numéricos
            cep = cep?.replace(/\D/g, "");

            // Verifica se a entrada é válida e formata apenas se tiver 8 caracteres
            if (cep?.length === 8) {
                // Aplica o formato XXXXX-XXX
                cep = cep?.replace(/^(\d{5})(\d{3})/, "$1-$2");
            }

            return cep;
        },
        formatarData(data) {
            if (/^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/.test(data)) return data;
            if (!data || data.includes('undefined') || !data.includes('-')) return '';
            const [ano, mes, dia] = data.split('-');
            if (ano.length !== 4 || mes.length !== 2 || dia.length !== 2) return '';
            return `${dia.padStart(2, '0')}/${mes.padStart(2, '0')}/${ano}`;
        },
        addTitular() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            const cpfWithoutPunctuation = this.titularUser.cnpj_cpf.replace(/[^\d]/g, '') || '';
            const nascimento = this.formatarData(this.pessoaFisica.data_de_nascimento);
            const identidade = this.pessoaFisica?.identidade.replace(/[^\d]/g, '') || '';
            const dataEmissao = this.formatarData(this.pessoaFisica.data_emissao_identidade);
            const estadoCivil = this.pessoaFisica.estado_civil_id;
            const ufOrgao = this.pessoaFisica.uf_orgao_expeditor;
            const expeditorOrgao = this.pessoaFisica.orgao_expeditor;
            const gender = this.pessoaFisica.sexo_id;
            const mae = this.pessoaFisica.nome_da_mae || '';
            const nacionalidade = this.pessoaFisica.nacionalidade || '';
            const naturalidade = this.pessoaFisica.naturalidade || '';
            const cargoId = this.pessoaFisica.cargo || '';
            const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            // const patrimonioQuestion = formatter.format(parseFloat(this.titularUser.valor_do_patrimonio?.replace(/[^\d,]/g, '').replace(',', '.'))).replaceAll(',', '');
            // const salarioQuestion = formatter.format(parseFloat(this.pessoaFisica.salario?.replace(/[^\d,]/g, '').replace(',', '.'))).replaceAll(',', '');
            let patrimonioQuestion = this.titularUser?.valor_do_patrimonio ?? null;
            if (/R\$/.test(this.titularUser?.valor_do_patrimonio))
                patrimonioQuestion = formatter.format(parseFloat(this.titularUser.valor_do_patrimonio?.replace(/[^\d,]/g, '').replace(',', '.'))).replaceAll(',', '')
            let salarioQuestion = this.pessoaFisica?.salario ?? null;
            if (/R\$/.test(this.pessoaFisica?.salario))
                salarioQuestion = formatter.format(parseFloat(this.pessoaFisica.salario?.replace(/[^\d,]/g, '').replace(',', '.'))).replaceAll(',', '');
            // const patrimonioQuestion = this.titularUser?.valor_do_patrimonio ?? null;
            // if (/R\$/.test(this.titularUser?.valor_do_patrimonio))
            // console.log('valor patrimonio', this.titularUser.valor_do_patrimonio)
            // const patrimonioQuestion = parseFloat(this.titularUser.valor_do_patrimonio);
            // console.log('patrimonio:', patrimonioQuestion);
            // const salarioQuestion = this.pessoaFisica?.salario ?? null;
            // if (/R\$/.test(this.pessoaFisica?.salario))
            // const salarioQuestion = parseFloat(this.pessoaFisica.salario);
            // console.log('salario:', salarioQuestion);
            const telefoneQuestion = this.user.phone_number;
            const emailId = this.user.email;
            const cepWithoutPunctuation = this.titularEndereco?.cep?.replace(/[^\d]/g, '') || '';
            const cidadeId = this.titularEndereco?.cidade || '';
            const ufId = this.titularEndereco?.uf || '';
            const numberWithoutPunctuation = this.titularEndereco?.numero?.replace(/[^\d]/g, '') || '';
            const bairroId = this.titularEndereco?.bairro || '';
            const complemento = this.titularEndereco?.complemento || '';
            const logradouroId = this.titularEndereco?.logradouro || '';
            const bancoId = this.titularUser?.numero_do_banco?.codigo ? this.titularUser.numero_do_banco.codigo : (this.titularUser?.numero_do_banco?.includes(':') ? this.titularUser.numero_do_banco.split(':')[0] : '') || '';
            const agenciaId = this.titularUser?.numero_da_agencia_bancaria?.replace(/[^\d]/g, '') || '';
            const contaId = this.titularUser?.numero_da_conta_corrente_bancaria?.replace(/[^\d]/g, '') || '';
            const tipoContaId = this.titularUser.tipo_de_conta_bancaria_id;
            // const rgFrenteId = this.titularUser.anexo_rg;
            // const rgTrasId = allQuestions.find(question => question.id === 'rgTras').answer;
            // const enderecoId = this.titularUser.anexo_comprovante_residencia;


            const data = {
                CPF: cpfWithoutPunctuation,
                DataDeNascimento: nascimento,
                Identidade: identidade,
                DataEmissaoIdentidade: dataEmissao,
                EstadoCivil: estadoCivil,
                Sexo: gender,
                NomeDaMae: mae,
                Nacionalidade: nacionalidade,
                Naturalidade: naturalidade,
                Cargo: cargoId,
                Complemento: complemento,
                ValorDoPatrimonio: patrimonioQuestion,
                Salario: salarioQuestion,
                TelefoneCelular: telefoneQuestion,
                Email: emailId,
                Cep: cepWithoutPunctuation,
                Cidade: cidadeId,
                UF: ufId,
                Numero: numberWithoutPunctuation,
                Bairro: bairroId,
                Logradouro: logradouroId,
                OrgaoExpedidor: expeditorOrgao,
                UFOrgaoExpedidor: ufOrgao,
                NumeroDoBanco: bancoId,
                NumeroDaAgenciaBancaria: agenciaId,
                NumeroDaContaCorrenteBancaria: contaId,
                TipoDeContaBancaria: tipoContaId,
            };

            if (this.currentQuestionIndex === 5) {
                data.anexo_rg = this.titularUser.anexo_rg.anexo ? this.titularUser.anexo_rg : { 'anexo': this.titularUser.anexo_rg };
                data.anexo_rg_tras = this.titularUser.anexo_rg_tras?.anexo ? this.titularUser.anexo_rg_tras : { 'anexo': this.titularUser.anexo_rg_tras };
            }

            if (this.currentQuestionIndex === 6) {
                data.anexo_comprovante_residencia = this.titularUser.anexo_comprovante_residencia?.anexo ? this.titularUser.anexo_comprovante_residencia : { 'anexo': this.titularUser.anexo_comprovante_residencia };
            }

            return axios
                .post(this.urlEnv + "updateInvestidor", data, config)
                .then(() => {
                })
                .catch((err) => {
                    if (
                        err.response &&
                        err.response.data &&
                        err.response.data.mensagem
                    ) {
                        const errors = err.response.data.mensagem;
                        const messageError = Object.values(errors).join(", ");
                        this.$toast.error(messageError);
                    } else {
                        console.error(err);
                    }
                    throw err
                });
        },
        backPage() {
            this.$parent.goToPreviousScreen();
        },
        formatarPhone(valor) {
            // Remove todos os caracteres não numéricos
            valor = valor?.replace(/\D/g, "");

            // Formatação para telefone com código do país e DDD
            if (valor?.length === 11) {
                valor = valor?.replace(/^(\d{2})(\d{5})(\d{4})$/, "+$1 ($2) $3");
            } else if (valor?.length === 10) {
                valor = valor?.replace(/^(\d{2})(\d{4})(\d{4})$/, "($1) $2-$3");
            } else if (valor?.length === 9) {
                valor = valor?.replace(/^(\d{5})(\d{4})$/, "$1-$2");
            } else if (valor?.length === 8) {
                valor = valor?.replace(/^(\d{4})(\d{4})$/, "$1-$2");
            }

            return valor;
        },
        formatarCPF(valor) {
            valor = valor?.replace(/\D/g, "");

            // Aplica a máscara do CPF (###.###.###-##)
            valor = valor?.replace(/(\d{3})(\d)/, "$1.$2");
            valor = valor?.replace(/(\d{3})(\d)/, "$1.$2");
            valor = valor?.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

            return valor;
        },

        formatarSalario(valor) {
            if (valor == null)
                return 'R$ ';
            valor = valor?.replace(/\D/g, "");
            valor = (parseFloat(valor) / 100).toFixed(2).replace(".", ",");
            valor = "R$ " + valor?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
            return valor;
        },
        validateCPF(cpf) {
            console.log(cpf)
            cpf = cpf?.replace(/\D/g, '');
            if (cpf?.length !== 11) {
                this.$toast.error('CPF deve ter 11 dígitos.');
                return false;
            }

            let sum = 0;
            let remainder;

            for (let i = 1; i <= 9; i++) {
                sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
            }

            remainder = (sum * 10) % 11;

            if ((remainder === 10) || (remainder === 11)) {
                remainder = 0;
            }

            if (remainder !== parseInt(cpf.substring(9, 10))) {
                this.$toast.error('Insira um CPF válido.');
                return false;
            }

            sum = 0;
            for (let i = 1; i <= 10; i++) {
                sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
            }

            remainder = (sum * 10) % 11;

            if ((remainder === 10) || (remainder === 11)) {
                remainder = 0;
            }

            if (remainder !== parseInt(cpf.substring(10, 11))) {
                this.$toast.error('Insira um CPF válido.');
                return false;
            }

            return true;
        },
        async getInvestidor() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                axios
                    .get(this.urlEnv + `getInvestidor`, config)
                    .then((response) => {
                        this.titularEndereco = response.data.data.investidor.endereco
                        if (this.titularEndereco == null)
                            this.titularEndereco = {};
                        this.titularUser = response.data.data.investidor;
                        this.titularUser?.anexo_rg
                            ? this.titularUser.anexo_rg = JSON.parse(this.titularUser.anexo_rg)
                            : null;
                        this.titularUser?.anexo_rg_tras
                            ? this.titularUser.anexo_rg_tras = JSON.parse(this.titularUser.anexo_rg_tras)
                            : null;
                        this.titularUser?.anexo_comprovante_residencia
                            ? this.titularUser.anexo_comprovante_residencia = JSON.parse(this.titularUser.anexo_comprovante_residencia)
                            : null;
                        if(this.titularUser?.numero_do_banco) this.titularUser.numero_do_banco = this.titularUser?.banco_formatado;
                        this.pessoaFisica = response.data.data.investidor.pessoa_fisica ?? { salario: null };

                        console.log(this.pessoaFisica, '1', this.titularUser, '2', this.titularEndereco, '3')
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } catch (err) {
                console.log(err);
            }
        },
        async getUserData() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            axios.get(this.urlEnv + "user", config).then(response => {
                // if(response.)
                console.log(response)
                let userData = response.data;
                this.user.email = userData.email;
                this.user.phone_number = userData.phone_number
                this.user.nome = userData.name
                //console.log(userData);
            }).catch(error => {
                console.error(error);
            })
        },
        async getEstadosCivis() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(
                    this.urlEnv + "getEstadosCivis",
                    config
                );
                this.estadoCivil = response.data.data.estados_civis;
            } catch (error) {
                console.error(error);
            }
        },
        async getOrgaoExpeditor() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(
                    this.urlEnv + "getOrgaoExpeditor",
                    config
                );
                this.expeditor = response.data.data.orgao_expeditor;
            } catch (error) {
                console.error(error);
            }
        },
        async getUf() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(
                    this.urlEnv + "getUf",
                    config
                );
                this.uf = response.data.data.uf;
            } catch (error) {
                console.error(error);
            }
        },
        async getSexos() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(this.urlEnv + "getSexos", config);
                this.genderItens = response.data.data.sexos;
            } catch (error) {
                console.error(error);
            }
        },
        async getPais() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(this.urlEnv + "getPais", config);
                this.nacionalidade = response.data.data.paises;
                this.naturalidade = response.data.data.paises;
                //console.log(this.nacionalidade, this.naturalidade);
            } catch (error) {
                console.error(error);
            }
        },
        async getBancos() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(this.urlEnv + "getBanco", config);
                this.banco = response.data.data.banco;
                // console.log(this.banco);
            } catch (error) {
                console.error(error);
            }
        },
        async getTiposConta() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                const response = await axios.get(this.urlEnv + "getTipoConta", config);
                this.tipoContaBancaria = response.data.data.tipos_de_conta;
                //console.log(this.tipoContaBancaria);
            } catch (error) {
                console.error(error);
            }
        },
        handleFileUpload(event, questionKey) {
            const file = event.target.files[0];
            const reader = new FileReader();

            reader.onload = (e) => {
                if (file.type.startsWith('image/')) {
                    const img = new Image();
                    img.src = e.target.result;

                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');

                        const maxWidth = 800;
                        const maxHeight = 600;
                        let width = img.width;
                        let height = img.height;
                        if (width > height) {
                            if (width > maxWidth) {
                                height *= maxWidth / width;
                                width = maxWidth;
                            }
                        } else {
                            if (height > maxHeight) {
                                width *= maxHeight / height;
                                height = maxHeight;
                            }
                        }
                        canvas.width = width;
                        canvas.height = height;

                        ctx.drawImage(img, 0, 0, width, height);

                        const dataURL = canvas.toDataURL('image/jpeg', 0.7);

                        const imageExists = this.uploadedImages.some(image => image.content === dataURL);
                        if (imageExists) {
                            this.$toast.error('Esta imagem já foi adicionada anteriormente.');
                            return;
                        }

                        const imageObject = {
                            nome: file.name,
                            content: dataURL
                        };

                        this.uploadedImages.push(imageObject);
                        this.titularUser[questionKey] = imageObject;
                    };
                } else if (file.type === 'application/pdf') {
                    const pdfObject = {
                        nome: file.name,
                        content: e.target.result
                    };
                    this.titularUser[questionKey] = pdfObject;
                    console.log('PDF detected. Compressing PDF...');
                } else {
                    console.log('Tipo de arquivo não suportado');
                }
            };

            reader.readAsDataURL(file);
        },



        async getInfo() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            try {
                axios
                    .get(this.urlEnv + `getMeusDados`, config)
                    .then((response) => {
                        this.titularUser = response.data.data.dados_pessoais;
                        // console.log(this.titularUser);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } catch (err) {
                console.log(err);
            }
        },
        openFileInput(index) {
            this.$refs['fileInput' + index].click(); // Abre o input file correspondente ao card clicado
        },
        getCamposObrigatoriosFaltando() {
            return this.camposObrigatorios.filter(campo => {
                const valorCampo = campo.split('.').reduce((obj, key) => obj[key], this);
                return !valorCampo;
            });
        },
        validateDate() {
            this.dateError = !this.pessoaFisica.data_de_nascimento;
        },
        handleButtonClick() {
            let isValid = true;

            const isFieldValid = (field) => {
                if (Array.isArray(field)) {
                    return field.length > 0;
                }
                return field !== null && field !== undefined && field.toString().trim() !== '';
            };

            // Função para validar o campo e definir o erro correspondente
            const validateField = (field, errorField) => {
                if (!isFieldValid(field)) {
                    this[errorField] = true;
                    isValid = false;
                } else {
                    this[errorField] = false;
                }
            };

            const isFileValid = (field) => {
                if(field.anexo)
                    return field.anexo && field.anexo.content && field.anexo.nome;
                else
                    return field && field.content && field.nome;
            }

            // Validar os campos conforme o índice da pergunta atual
            if (this.currentQuestionIndex === 0) {
                validateField(this.titularUser.cnpj_cpf && this.validateCPF(this.titularUser.cnpj_cpf) ? 
                this.titularUser.cnpj_cpf : null, 'cpfError');
                validateField(this.pessoaFisica.data_de_nascimento, 'dateError');
                validateField(this.pessoaFisica.identidade, 'identidadeError');
                validateField(this.pessoaFisica.data_emissao_identidade, 'emissaoError');
                validateField(this.pessoaFisica.orgao_expeditor, 'expedidorError');
                validateField(this.pessoaFisica.uf_orgao_expeditor, 'ufExError');
            } else if (this.currentQuestionIndex === 1) {
                validateField(this.pessoaFisica.sexo_id, 'sexoError');
                validateField(this.pessoaFisica.estado_civil_id, 'civilError');
                validateField(this.pessoaFisica.nacionalidade, 'nacionalidadeError');
                validateField(this.pessoaFisica.naturalidade, 'naturalidadeError');
                validateField(this.pessoaFisica.nome_da_mae, 'maeError');
            } else if (this.currentQuestionIndex === 2) {
                let salario = this.pessoaFisica.salario;
                if (/R\$/.test(salario)) {
                    salario = parseFloat(salario.replace(/[^\d,]/g, '').replace(',', '.'));
                }
                validateField(this.pessoaFisica.cargo, 'cargoError');
                validateField(this.titularUser.valor_do_patrimonio, 'patrimonioError');
                validateField(salario && parseFloat(salario) >= 1320, 'salarioError');
            } else if (this.currentQuestionIndex === 3) {
                validateField(this.titularUser.cnpj_cpf, 'cepError');
                validateField(this.titularEndereco?.logradouro, 'ruaError');
                validateField(this.titularEndereco?.numero, 'numeroError');
                validateField(this.titularEndereco?.bairro, 'bairroError');
                validateField(this.titularEndereco?.cidade, 'cidadeError');
                validateField(this.titularEndereco?.uf, 'ufError');
            } else if (this.currentQuestionIndex === 4) {
                validateField(this.titularUser.numero_do_banco, 'numeroBancoError');
                validateField(this.titularUser.numero_da_agencia_bancaria, 'agenciaError');
                validateField(this.titularUser.numero_da_conta_corrente_bancaria, 'numeroContaError');
                validateField(this.titularUser.tipo_de_conta_bancaria_id, 'tipoContaError');
            } else if (this.currentQuestionIndex === 5) {
                if (!isFileValid(this.titularUser.anexo_rg)) {
                    this.$toast.error('Envie o documento frontal (RG).');
                    isValid = false;
                }
            } else if (this.currentQuestionIndex === 6) {
                if (!isFileValid(this.titularUser.anexo_comprovante_residencia)) {
                    this.$toast.error('Envie o documento de comprovante de residência.');
                    isValid = false;
                }
            }

            if (isValid == true) {
                if (this.currentQuestionIndex === 0 || this.currentQuestionIndex === 1 || this.currentQuestionIndex === 2 || this.currentQuestionIndex === 3 || this.currentQuestionIndex === 4) {
                    this.addTitular().then(() => {
                        this.currentQuestionIndex++;
                    }).catch((error) => {
                        // this.currentQuestionIndex = 0
                        console.log(error);
                    });
                } else if (this.currentQuestionIndex === 5) {
                    if (this.titularUser.anexo_rg) {
                        this.addTitular().then(() => {
                            this.currentQuestionIndex++;
                        }).catch((error) => {
                            // this.currentQuestionIndex = 0
                            console.log(error);
                        });
                    } else {
                        console.log("Por favor, envie os anexos antes de prosseguir.");
                    }
                } else if (this.currentQuestionIndex === 6) {
                    if (this.titularUser.anexo_comprovante_residencia) {
                        this.finishStage()
                    }
                }
            } else {
                console.log("Por favor, corrija os erros antes de prosseguir.");
            }
        },

        finishStage() {
            const config = {
                headers: { Authorization: `Bearer ${this.token}` },
            };
            const data = {
                questions: this.stages[this.currentStage].questions,
                dataComplete: true
            };
            console.log(data)
            try {
                // axios
                //     .post(this.urlEnv + "saveQuestions", data, config)
                //     .then((response) => {
                //         console.log(response.data);
                //         this.$emit('incrementPosition')
                //         this.$parent.goToNextScreen();
                //     })
                //     .catch((error) => {
                //         this.$toast.error(error);
                //     });
                axios
                    .post(this.urlEnv + "saveQuestions", data, config)
                    .then(() => {
                        this.addTitular().then(() => {
                            this.$emit('incrementPosition');
                            this.$parent.goToNextScreen();
                        }).catch((error) => {
                            this.$toast.error(error);
                        });
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            } catch (error) {
                console.log(error);
            }
        },

        getColor(position) {
            return position <= this.currentPosition ? 'secondary' : 'gray';
        },
        async fetchCEPData(cep) {
            const response = await axios.get(
                `https://viacep.com.br/ws/${cep}/json/`
            );
            const data = response.data;

            if (!data.erro) {
                console.log(cep)
                this.titularEndereco.logradouro = data.logradouro;
                this.titularEndereco.bairro = data.bairro;
                this.titularEndereco.cidade = data.localidade;
                this.titularEndereco.uf = data.uf;
                this.$forceUpdate();
            } else {
                console.error('CEP not found');
            }
        },
    }

}
</script>

<style scoped>
.row {
    margin: -20px !important
}

.text-h4,
.text-xl-h4,
.text-xl-h5,
.text-h3,
.text-h5 .text-h6,
.white--text,
.primary--text,
.text-center,
.d-flex {
    font-family: 'Raleway', cursive !important;
}

.custom-container {
    /* max-width: 1440px; */
    margin: 0 auto;
    border: 1px solid #fff;
    padding: 20px 0;
}

.stage-indicator {
    display: inline-block;
    width: 100px;
    /* Ajuste conforme necessário */
    padding: 5px;
    margin: 0 10px;
    /* Espaçamento entre as etapas */
    border: 1px solid #ccc;
    border-radius: 5px;
}

.v-timeline::before {
    transform: rotate(90deg) !important;
}

.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse)::before {
    left: 0 !important
}

.footer {
    background-color: #00265b;
    color: white;
    padding: 8px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9999;
  }

  .v-sheet.v-card:not(.v-sheet--outlined) {
    background: transparent !important;
    border: 1px solid #14AA95 !important;
  }

</style>

<style>

.fieldColor .v-label{ 
  color: white !important
}
</style>